<template>
  <StaticPage>
    <el-card class="form_wrapper">
      <h2>{{ $t('resetPassword.header') }}</h2>
      <div class="resetPassword_box" v-show="isAuthorized">
        <div class="from_box" v-show="formFlag">
          <el-form :model="ruleResetPasswordForm" :rules="rulesResetPassword" ref="ruleResetPasswordForm">
            <p>{{ $t('common.formValidation.newPwAccFormat') }}</p>
            <InputTemplate
              class="mb-5"
              v-model="ruleResetPasswordForm.pass"
              show-password
              name="pass"
              :label="$t('common.field.newPw')"
            >
            </InputTemplate>
            <InputTemplate
              v-model="ruleResetPasswordForm.checkPass"
              show-password
              name="checkPass"
              :label="$t('common.field.confirmPw')"
            >
            </InputTemplate>
            <el-button @click.native="submitForm('ruleResetPasswordForm')" data-testid="submit">
              {{ $t('common.button.submit') }}
            </el-button>
          </el-form>
        </div>
        <div class="result" v-show="successFlag">
          <i class="icon el-icon-circle-check text-green mb-3"></i>
          <p>{{ $t('resetPassword.succ') }}</p>
          <router-link :to="{ name: 'home' }" class="el-button" data-testid="bkToHm">
            {{ $t('common.button.bkToHm') }}
          </router-link>
        </div>
      </div>
      <div class="resetPassword_box" v-show="isNotAuthroized">
        <p class="no_authroization">{{ $t('resetPassword.failed') }}</p>
      </div>
    </el-card>
    <PasswordRenewalReminderDialog :reminderDialogVisible.sync="reminderDialogVisible" @confirmSubmit="confirmSubmit" />
  </StaticPage>
</template>

<script>
import { rsa } from '@/util/encrypt';
import StaticPage from '@/components/template/staticPage/StaticPageDefault';
import { apiCheckResetPasswordAuthorization, apiSubmitResetMt4AccountPassword } from '@/resource';
import PasswordRenewalReminderDialog from '@/components/auth/PasswordRenewalReminderDialog.vue';

export default {
  name: 'ResetPassword',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else {
        const reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,16}$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('common.formValidation.newPwAccFormat')));
        }
      }
      callback();
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else if (value !== this.ruleResetPasswordForm.pass) {
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')));
      }
      callback();
    };
    return {
      isAuthorized: false,
      isNotAuthroized: true,
      formFlag: true,
      successFlag: false,
      ruleResetPasswordForm: {
        pass: '',
        checkPass: '',
        token: ''
      },
      rulesResetPassword: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }]
      },
      reminderDialogVisible: false
    };
  },
  components: { StaticPage, PasswordRenewalReminderDialog },
  mounted() {
    const token = this.$route.query.token;
    this.verifyAuthorization(token);
  },
  methods: {
    verifyAuthorization(token) {
      apiCheckResetPasswordAuthorization({
        token: this.$route.query.token
      }).then(resp => {
        if (resp.data.code == 0) {
          this.ruleResetPasswordForm.token = resp.data.data;
          this.isAuthorized = true;
          this.isNotAuthroized = !this.isAuthorized;
        } else {
          this.isAuthorized = false;
          this.$router.push('/login');
        }
      });
    },
    confirmSubmit() {
      this.updatePassword();
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.reminderDialogVisible = true;
        } else {
          return false;
        }
      });
    },
    updatePassword() {
      apiSubmitResetMt4AccountPassword({
        token: this.ruleResetPasswordForm.token,
        newPassword: rsa(this.ruleResetPasswordForm.pass)
      })
        .then(resp => {
          if (resp.data.code == 0 && resp.data.data == true) {
            this.formFlag = false;
            this.successFlag = true;
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/loginClient/login.scss';
@import '@/assets/css/pages/resetPassword.scss';
</style>
